/* ------------------------------------------- */
/* kitecamp page css here  */
/* --------------------------------------------- */


/* this css only for herosec all pages */


.page_cont {
  position: relative;
  width: 100%;
  /* height: 103vh; */
  /* Full screen height */
  overflow: hidden;

}

/* -------------------- */

/* trips js vedio css  */

/* 
.video-container {
  position: relative;
  width: 100%;
  height: 180vh;
  overflow: hidden;
  margin-top: -13%;
} */


.headerbackimg {
  width: 100%;
  height: 60vh;
  z-index: -1;
}

.Map_container {
  position: relative;
  width: 100%;
  height: 150vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-top: -25%;
}

.map {
  /* width: 100%; */
  /* height: 100vh; */
  margin-top: 25%;
  border-radius: 10px;
}

/* .card:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
} */



.trip_main_card {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 830px;
}

.kite_text {
  color: black;
  font-weight: 900;
  font-size: 30px;
  text-align: center;
  font-family: "Amatic SC", sans-serif;
  text-transform: uppercase;
}


.search_filter_kitecamp {
  margin: 2rem 0 1rem 0 !important;
  padding: 0 !important;
}

.calendar_div {
  background-color: #fff7cc;
  border-radius: 15px !important;
  padding: 15px;
}

.month_title {
  text-align: center;
  color: black;
  font-weight: 900;
  font-size: 30px;
  line-height: 53px;
  font-family: "Amatic SC", sans-serif;
  text-transform: uppercase;

}

.crdbody {
  cursor: pointer;
}

.Crd_title {
  color: white;
  font-weight: 900;
  font-size: 60px;
  line-height: 53px;
  text-align: center;
  font-family: "Amatic SC", sans-serif;
  text-transform: uppercase;
  margin-top: 5px;
  margin: 5%;
}

.map_hover_card {
  max-width: 200px;
  display: flex;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.map_hover_card .map_card_img {
  width: 50%;
  height: auto;
  margin: 10px 0 0 0 !important;
  border-radius: 5px;
}

.map_hover_card h5 {
  margin: 30px 0 0 15px;
  font-size: 22px;
  font-family: "Amatic SC", sans-serif;
  font-weight: 900;
}

.gm-style-iw-d {
  padding: 0;
  margin: 0;
}

/* .gm-style .gm-style-iw-c{
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0;
} */

.gm-style-iw-chr {
  display: none;
}

@media only screen and (max-width: 600px) {
  .map {
    width: 100%;
    height: 70%;
    margin-top: 30%;
    border-radius: 8px;
  }

  .search_filter_kitecamp {
    margin-top: 3rem !important;
  }


  .Map_container {
    position: relative;
    width: 100%;
    height: 90vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    margin-top: -25%;
  }

  .Map_container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

}



/* ---------------------------------- */

/* Stories Page css here --------------- */
/* ---------------------------------- */

.strories_ban {
  position: relative;
}



.stroies_cont {
  background-color: #000;
  margin: 5%;
  position: absolute;
  bottom: 1%;
  z-index: 1;
  left: 14%;
  border-radius: 20px;
  width: 70% !important;
}

.storiesbtn {
  background-color: #fdc016;
  color: #000;
  width: 60%;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 25px;
  margin: 18px !important;
  border: 0;
  border-radius: 5px;
  text-decoration: none;

}

.stories_card {
  margin-top: 15%;
}


/* Small devices (phones, 576px and up)------------------ */

@media only screen and (max-width: 600px) {
  .stroies_cont {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 768px) {
  .stroies_cont {
    display: none;
  }
}





/* ------------------------------- */
/* School page css here  */
/* ------------------------------- */

.w_K_sec {
  background-color: #fff6db;
  margin-top: 8%;
  padding: 5%;
}

.school_heading {
  color: black;
  font-weight: 900;
  font-size: 40px;
  line-height: 53px;
  font-family: "Amatic SC", sans-serif;
  text-transform: uppercase;
}


.school_para {
  color: black;
  font-family: sans-serif;
  margin-top: 5%;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  letter-spacing: 2PX;

}

.w_K_img {
  width: 60%;
  border-radius: 10px;
  margin-top: 5%;
}


.t_M_img {
  width: 12%;
  margin: 10px;
}


/* -------------- */
/* small device   css */
/* -------------- */


@media only screen and (max-width: 600px) {

  .w_K_img {
    width: 100%;
    border-radius: 10px;
    margin-top: 5%;
  }

  .t_M_img {
    width: 70%;
  }

  .storiesbtn {
    width: 80%;
  }

}




/* ------------------------------------------------------------- */
/* booking  css  */
/* ------------------------------------------------------------- */

.booking_cont {
  background-color: black;
  color: white;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  padding: 5px 70px !important;
  z-index: 10000;
  background: #000;
}

.booking_logo {
  width: 30%;
  margin: 5px;
}

.show_trip_name {
  font-size: 35px;
  margin-left: 80px;
  font-weight: bold;
  font-family: "Amatic SC", sans-serif;
  /* padding-top: 15px; */
}


.Booking_btn {
  background-color: #fdc016;
  color: white;
  font-size: 16px;
  padding: 9px 25px;
  margin: 18px !important;
  border: 0;
  border-radius: 5px;
  text-decoration: none;
}

.booking_item_span {
  margin-left: 20px;
}

.right_div {
  margin-top: 10px;
  float: right;
}

.right_div span {
  font-size: 20px;
  font-weight: 500;
}

.wp_icon {
  /* float: right !important; */
  font-size: 30px;
}


@media only screen and (max-width: 600px) {
  .booking_item_span {
    margin-left: 20px !important;
    font-size: 15px !important;
    text-align: center;
  }

  .booking_cont {
    margin: 0;
    max-width: 500px;
    padding: 5px !important;
  }

  .booking_logo {
    width: 38%;
    margin-right: 0;
    margin: 0;
  }

  .show_trip_name {
    font-size: 25px;
    margin-left: 40%;
    padding: 0;
  }

  .right_div {
    margin: 2px;
    justify-content: center;
    float: left;
  }

  .wp_icon {
    float: right !important;
  }

  .wp_icon {
    float: right !important;
    font-size: 25px;
  }



  .Booking_btn {
    margin: 0px !important;
    margin-left: 0px !important;
    font-size: 18px !important;
    padding: 2px 10px;
  }

}



/* Medium devices (tablets, 768px and up) */
@media (max-width: 1024px) and (min-width: 500px) {

  .left_div {
    margin-left: 200px;
  }

  .right_div {
    margin-right: 60px;
  }
}


/* ------------------------------------- */
/* calendar CSS  */
/* ------------------------------------- */


.calbg_cont {
  position: relative;
  background-image: url('../Images/Calbanimg6.png');
  width: 100%;
  height: 50vh;
  background-size: auto;
  background-repeat: no-repeat;
  margin-top: 2rem;
}

.calbg_cont2 {
  position: relative;
  background-image: url('../Images/Calbanimg7.png');
  width: 100%;
  height: 50vh;
  background-size: auto;
  background-repeat: no-repeat;
  margin-top: 2rem;

}

.calbg_cont3 {
  position: relative;
  background-image: url('../Images/Calbanimg8.png');
  width: 100%;
  height: 50vh;
  background-size: auto;
  background-repeat: no-repeat;
  margin-top: 2rem;

}

.calbg_cont4 {
  position: relative;
  background-image: url('../Images/Calbanimg4.png');
  width: 100%;
  height: 50vh;
  background-size: auto;
  background-repeat: no-repeat;
  margin-top: 2rem;

}


.calbg_cont5 {
  position: relative;
  background-image: url('../Images/Calbanimg2.png');
  width: 100%;
  height: 50vh;
  background-size: auto;
  background-repeat: no-repeat;
  margin-top: 2rem;

}

.calbg_cont6 {
  position: relative;
  background-image: url('../Images/Calbanimg3.png');
  width: 100%;
  height: 50vh;
  background-size: auto;
  background-repeat: no-repeat;
  margin-top: 2rem;

}

.calbg_cont7 {
  position: relative;
  background-image: url('../Images/Calbanimg4.png');
  width: 100%;
  height: 50vh;
  background-size: auto;
  background-repeat: no-repeat;
  margin-top: 2rem;

}

.cal_title {
  color: #fdc016;
  font-weight: 900;
  font-size: 40px;
  line-height: 53px;
  text-align: center;
  font-family: "Amatic SC", sans-serif;
  text-transform: uppercase;
  margin-top: 2rem;

}

.cal_card {
  margin-top: 2rem;
  background-color: transparent !important;
  color: #fff !important;
  border: 3px solid #fdc016 !important;
  transition: all 0.3s ease-in-out;
  /* border: 1px solid #fdc016 !important; */
}

.cal_card:hover {
  background-color: #0000005c !important;
  color: #fff !important;
  border: 4px solid #ffffff00 !important;
  cursor: pointer;

}

.calendar_text {
  margin-left: 5px;
  font-weight: 900;
  font-weight: bold;
  font-family: "Amatic SC", sans-serif;
  font-size: 22px !important;
}


.cal_card_head {
  color: #fff;
  font-weight: 900;
  font-size: 30px;
  line-height: 53px;
  font-family: "Amatic SC", sans-serif;
  text-transform: uppercase;
}


.col_main_heading {
  font-family: "Amatic SC", sans-serif;
  text-align: center;
  font-size: 50px;
  font-weight: 900;
  margin: 15px;
}

.cal_searchbtn2 {
  display: inline-block;
  width: fit-content;
  min-width: 120px;
  height: 45px;
  padding: 10px 16px;
  margin: 0 0 8px 0;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fdc016;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  user-select: none;
  font-family: 'Poppins', sans-serif;
}

.cal_searchbtn2:hover {
  background-color: #f3c23a;
  color: white;
}


@media only screen and (max-width: 600px) {

  .col_main_heading {
    font-family: "Amatic SC", sans-serif;
    text-align: center;
    font-size: 30px;
    font-weight: 900;
    margin: 1rem 0 0 0;
  }

  .calbg_cont,
  .calbg_cont2,
  .calbg_cont3,
  .calbg_cont4,
  .calbg_cont5,
  .calbg_cont6,
  .calbg_cont7 {
    background-image: url('../Images/Calbanimg6.png');
    width: 100%;
    height: auto !important;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 2rem;
    padding-bottom: 1rem;
  }
}

@media (max-width: 1024px) and (min-width: 500px) {

  .calbg_cont,
  .calbg_cont2,
  .calbg_cont3,
  .calbg_cont4,
  .calbg_cont5,
  .calbg_cont6,
  .calbg_cont7 {
    background-image: url('../Images/Calbanimg6.png');
    width: 100%;
    height: auto !important;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 2rem;
    padding-bottom: 1rem;
  }

}
.nav_pos {
    position: fixed;
    background: transparent;
    z-index: 1000;
    margin-top: 0;
}


#navbarManage {
    background-color: transparent;
    position: static;
    transition: 1s;

}

#navbarManage.scrolled {
    background-color: #fdfaec;
    position: fixed;
    top: 0;
    animation-name: animate;
    animation-duration: 0.1s;

}

@keyframes animate {
    from {
        top: -10px;

    }

    to {
        top: 0;

    }
}


.language_div {
    /* text-align: right; */
    padding: 0 2rem 1rem 0;
    /* margin-top: 10px; */
    /* position: relative; */
    z-index: 100;
}

.LNG_select_dropdown {
    background-color: transparent;
    color: white !important;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 16px;
}

/* Style the dropdown options */
.LNG_select_option {
    background-color: transparent;
    color: black;
}



.navbar {
    background-color: #000;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    /* margin-top: 15px; */
    z-index: 20;

}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
    position: relative;
}

.nav-logo {
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-items: center;
}

.nav-logo .icon {
    display: inline-block;
    width: 15rem;
    height: 3rem;
    margin-left: 16px;
    margin-top: 0;
}

.logo {
    width: 80%;
    margin-top: 0px;
    padding-bottom: 80px;
}

.nav-menu {
    display: flex;
    list-style: none;
    /* text-align: start !important; */
    margin-right: 25rem;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-weight: bold !important;
    height: 100%;
    text-transform: uppercase;
    border-bottom: 3px solid transparent;
}

/* .fa-code {
    margin-left: 1rem;
  } */

.nav-item {
    line-height: 40px;
    margin-right: 1rem;
}




.nav-icon {
    display: none;
    color: #f5b921;
}

.nav-icon .icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
}




/* NavBar.css */
.nav-item {
    position: relative;
}

.Dp_dW {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 250px;
    text-align: left;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.nav-item:hover .Dp_dW {
    display: block;
}

.submenu-item {
    padding: 0px;
    margin: 0;
    list-style: none;
}

.submenu-item .nav-links {
    text-decoration: none;
    color: #000;
    padding: 0;
    display: block;
}

.submenu-item .nav-links:hover {
    color: #ffdd40;
}


.Only_mobile {
    display: none;
}

.fa_icon_sidebar {
    color: #ffc800;
}

@media screen and (max-width: 960px) {
    .Only_mobile {
        display: block;
    }

    .navbar {
        background-color: transparent;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        position: sticky;
        top: 0;
        z-index: 20;
        margin: 0 !important;
        margin-top: 0 !important;
        padding: 0 !important;
    }


    .nav-container {
        background-color: black;
        margin: 0 !important;
        padding: 0 !important;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1pxsolid #fff;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: black;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-item .active {
        color: rgb(255, 255, 255);
        border: none;
    }

    .nav-links {
        color: #fff;
        padding: 2px;
        width: 100%;
        display: table;
    }

    .nav-logo .icon {
        width: 15rem;
        height: auto;
    }

    .logo {
        width: 80%;
        text-align: left;
        padding-bottom: 0px;
    }

    .nav-icon {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-100%, -50%);
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        color: #ffdd40;
    }

    .language_div {
        display: none;
    }
}
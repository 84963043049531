/* level css here  */



.level_container {
    background-color: black;
    min-height: 100vh;
}

.background_black {
    background-color: #000;
    height: 150vh;
}

.level_heading {
    text-transform: uppercase;
    color: #ffc800;
    font-weight: bold;
    font-size: 22px;
    margin-top: 15px;
}

.tripName {
    max-width: 900px;
    color: white;
    text-transform: uppercase;
    font-family: "Amatic SC", sans-serif;
    letter-spacing: 3px;
    text-align: center;
    font-size: 60px !important;
    font-weight: bolder;
    margin-bottom: 5%;
}

.level_input {
    padding: 10px !important;
}

@media (max-width: 768px) {
    .tripName {
        font-size: 30px !important;
    }

    .level_heading {
        font-size: 15px;
    }

    .level_input {
        padding: 5px !important;
    }

    /* .level_crd_price {
        margin-left: 16% !important;
    } */


}



.person_week {
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-size: 10px;
    margin-top: 35px;
}



.form_crd_row {
    border-radius: 10px;
    /* box-shadow: 2px 2px 2px 2px #b7b7b7; */
    background-color: white;
}

.level_crd_text {
    margin-top: 20px;
    font-size: 22px;
}

.level_crd_para {
    padding-left: 16px;
}

.add_num {
    font-size: 22px;
}


.moreinfo_btn {
    color: #ffc800;
    font-size: 20px;
}

.plus_minus_col {
    float: right !important;
}

.P_M_icon {
    cursor: pointer;
    font-size: 30px;
    margin: 10px;
    color: #ffc800;
}

.btn_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    position: relative;
    z-index: 1;
}

.level_btn {
    background-color: #ffc800;
    color: black;
    font-size: 25px;
    margin: auto;
    width: 65%;
    padding: 5px;
    border: 0;
    text-decoration: none;
    border-radius: 8px;
    position: relative;
    z-index: 1;
}

.p_m_col {
    float: right;
    margin-top: -50px;
    margin-left: 10%;
}



/* Constrain the width of the dropdown content */
.styled-dropdown {
    position: relative;
    width: 60% !important;
    /* overflow: hidden; */
}

.styled-dropdown summary {
    cursor: pointer;
}

.summary-header {
    display: block;
    font-size: 15px;
    padding: 5px;
    color: black;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    width: auto;
    max-width: 25%;
}

.dropdown-content {
    margin-top: 10px;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
    width: 100%;
    box-sizing: border-box;
}


.dropdown-content p {
    margin: 0 0 8px;
    color: #393939;
    display: flex;
    align-items: center;
}



@media only screen and (max-width: 600px) {

    .level_btn {
        background-color: #ffc800;
        color: black;
        font-size: 20px;
        margin: auto;
        width: 100%;
        border: 0;
        text-decoration: none;
        border-radius: 8px;
    }

    .form_crd_row {
        border-radius: 10px;
        background-color: white;
    }

    .level_crd_text {
        margin-top: 12px;
        font-size: 15px;
    }

    .check_box_text {
        font-size: 12px;
    }

    .add_num {
        font-size: 18px;
    }

    .P_M_icon {
        cursor: pointer;
        font-size: 25px;
        margin: 5px;
        margin-top: 5px !important;
        padding-top: 0 !important;
        color: #ffc800;
    }


    .checkout_data {
        font-size: 15px !important;
    }

    .styled-dropdown {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .styled-dropdown summary {
        cursor: pointer;
    }

    .summary-header {
        display: block;
        font-weight: bold;
        font-size: 12px;
        color: black;
        background-color: white;
        border: 1px solid black;
        border-radius: 8px;
        width: auto;
        max-width: 90%;
    }

    .p_m_col {
        float: right;
        margin-top: 0px;
        margin-left: 0%;
    }

}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 1024px) and (min-width: 500px) {
    .summary-header {
        display: block;
        font-weight: bold;
        font-size: 12px;
        color: black;
        background-color: white;
        border: 1px solid black;
        border-radius: 8px;
        margin-top: 9x;
        width: auto;
        max-width: 100%;
    }

    .p_m_col {
        float: right;
        margin-top: 0px;
        margin-left: 0%;
    }
}


/* checkout component css  */

.checkout_data {
    font-size: 17px;
    margin: 5px;
    color: #fff;
}


/* From Uiverse.io by ErzenXz */
.col_input {
    text-align: left;
}

.input {
    width: 100%;
    max-width: 280px;
    height: 45px;
    justify-content: left;
    padding: 12px;
    border-radius: 12px;
    border: 1.5px solid lightgrey;
    outline: none;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0px 0px 20px -18px;
}

.input:hover {
    border: 2px solid lightgrey;
    box-shadow: 0px 0px 20px -17px;
}

.input:active {
    transform: scale(0.95);
}

.input:focus {
    border: 2px solid grey;
}


.room_cont {
    height: 100% !important;
}



.room-details {
    display: none;
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .room-details {
        display: block;
        background-color: #f8f9fa;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    }

    .room_container_desktop {
        display: none !important;
    }
}


.coupon_btn {
    background-color: #ffc800;
    color: black;
    font-size: 20px;
    margin: 0 0 0 35px;
    width: 50%;
    padding: 8px 0 8px 0;
    border: 0;
    text-decoration: none;
    border-radius: 8px;
}

@media only screen and (max-width: 600px) {

    .input {
        width: 100%;
        max-width: 280px;
        height: 40px;
        justify-content: left;
        padding: 12px;
        border-radius: 12px;
        border: 1.5px solid lightgrey;
        outline: none;
        transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
        box-shadow: 0px 0px 20px -18px;
    }

    .coupon_btn {
        background-color: #ffc800;
        color: black;
        font-size: 20px;
        margin: auto;
        width: 80%;
        font-size: 15px;
        padding: 8px 0 8px 0;
        border: 0;
        text-decoration: none;
        border-radius: 8px;
    }
}



/* --------------------------------  */
/* userinfo  component css  */
/* --------------------------------  */



.form_head {
    font-size: 18px;
    font-weight: bold;
}


/* From input field css */
.mat-input-fordob {
    border-width: 0 0 1px;
    outline: none;
    background-color: #0000000a;
    border-radius: 4px 4px 0 0;
    padding: 1.5em 0.70em 0.70em;
    border-color: #393838;
    border-style: solid;
    font-size: inherit;
    width: 100% !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

#root-portal .react-datepicker-popper {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 1050;
    /* Ensure it appears above other elements */
}

.mat-input {
    -webkit-appearance: none !important;
    appearance: none !important;
    margin: 1px;
    padding: 1px;
    position: relative;
    font-size: inherit;
}

.mat-input input {
    border-width: 0 0 1px;
    outline: none;
    background-color: #0000000a;
    border-radius: 4px 4px 0 0;
    padding: 1.5em 0.70em 0.70em;
    border-color: #393838;
    border-style: solid;
    font-size: inherit;
    transition: 0.1s;
}

.mat-input input:hover,
.mat-input input:focus {
    border-bottom-width: 2px;
    padding-bottom: calc(0.75em - 1px);
}

.mat-input input:hover {
    border-color: #ffc800;
}

.mat-input input:focus {
    border-color: #ffc800;
}

.mat-input label {
    position: absolute;
    top: 1.4em;
    left: 1em;
    pointer-events: none;
    transition: 0.2s;
    color: #0009;
}

.mat-input:has(input:focus) label {
    color: #ffc800;
}

.mat-input:has(input:focus) label,
.mat-input:not(:has(input:placeholder-shown)) label {
    top: 0.75em;
    font-size: 0.8em;
}

.mat-input input::placeholder {
    opacity: 0;
    transition: 0.2s;
    color: #2c2c2c;
}

.mat-input input:focus::placeholder {
    opacity: 1;
}



/* in form  select box css  */
/* From input field css */
.mat-input {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    font-size: inherit;
}


.forDob {
    -webkit-appearance: none;
    appearance: none;
    width: 100% !important;
    border: 1px solid #8c8c8c !important;
    border-radius: 4px !important;
    color: #606060;
    padding: 1em !important;
    font-size: inherit !important;
}

.mat-input input {
    border-width: 0 0 1px;
    outline: none;
    background-color: #0000000a;
    border-radius: 4px 4px 0 0;
    padding: 1.5em 0.70em 0.70em;
    border-color: #8c8c8c;
    border-style: solid;
    font-size: inherit;
    transition: 0.1s;
}

.mat-input input:hover,
.mat-input input:focus {
    border-bottom-width: 2px;
    padding-bottom: calc(0.75em - 1px);
}

.mat-input input:hover {
    border-color: #ffc800;
}

.mat-input input:focus {
    border-color: #ffc800;
}

.mat-input label {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    top: 1.4em;
    left: 1em;
    pointer-events: none;
    transition: 0.2s;
    color: #0009;
}

.mat-input:has(input:focus) label {
    -webkit-appearance: none;
    appearance: none;
    color: #ffc800;
}

.mat-input:has(input:focus) label,
.mat-input:not(:has(input:placeholder-shown)) label {
    top: 0.75em;
    font-size: 0.8em;
}

.mat-input input::placeholder {
    opacity: 0;
    transition: 0.2s;
    color: #2c2c2c;
}

.mat-input input:focus::placeholder {
    opacity: 1;
}

.mat-input select {
    -webkit-appearance: none;
    appearance: none;
    width: 100% !important;
    border: 1px solid #8c8c8c !important;
    border-radius: 4px !important;
    color: #606060;
    padding: 1em !important;
    font-size: inherit !important;
    background-color: #0000000a !important;
    transition: border-color 0.2s !important;
}

.mat-input select:hover,
.mat-input select:focus {
    -webkit-appearance: none;
    appearance: none;
    border-color: #ffc800 !important;
    outline: none !important;
}




/* toggel btn for same information for Traveller 1 */

/* From Uiverse.io by TimTrayler */
.switch {
    --secondary-container: #dbd0ac;
    --primary: #ffc800;
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3.4em;
    height: 1.5em;
}

.switch input {
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b4b4b4;
    transition: .2s;
    border-radius: 30px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1.2em;
    width: 1.2em;
    border-radius: 20px;
    left: 0.2em;
    bottom: 0.2em;
    background-color: #393939;
    transition: .4s;
}

input:checked+.slider::before {
    background-color: var(--primary);
}

input:checked+.slider {
    background-color: var(--secondary-container);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--secondary-container);
}

input:checked+.slider:before {
    transform: translateX(1.9em);
}




/* ------------------------------------- */
/* Form header css here  */
/* ------------------------------------- */
.left_icon {
    font-size: 35px;
    color: #ffc800;
    font-weight: 800;
    margin-top: 5%;
    cursor: pointer;
}

.header_img {
    width: 75%;
    margin-left: 8%;
}

.que_mark {
    margin-top: 10px;
    color: #ffc800;
    font-size: 30px;
    cursor: pointer;
}

.close_btn {
    cursor: pointer;
}


.popup-box {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    width: 550px !important;
    z-index: 1000;
    text-align: left;
    font-size: 14px;
}

.popup-box p {
    margin-bottom: 15px;
    line-height: 1.3;
    color: #333;
}

.popup-box .fw-bold {
    font-size: 16px;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-box .close_btn {
    cursor: pointer;
    font-size: 18px;
    color: #ff0000;
    transition: color 0.3s ease;
}

.popup-box .close_btn:hover {
    color: #cc0000;
}


@media only screen and (max-width: 600px) {
    .popup-box {
        width: 310px !important;
    }

    .que_mark {
        margin-top: 0px;
        color: #ffc800;
        font-size: 20px;
        cursor: pointer;
    }

    .left_icon {
        margin-top: 0px;
        font-size: 25px;
    }

    .header_img {
        width: 100%;
        margin-left: 0 !important;

    }

}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.loader {
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.date_container{
  background-color: black;
  min-height: 100vh;
}

.person_week {
  font-size: 22px;
}

.cal_head {
  text-transform: uppercase;
  color: #ffc800;
  font-weight: bold;
  font-size: 22px;
  margin-top: 15px;

}

.datepick_border {
  border: 1px solid #ffc800;
  margin: 2%;
}

.booked-date {
  background-color: red !important;
  color: white;
}

.few-left-date {
  background-color: orange !important;
  color: white;
}

.available-date {
  background-color: green !important;
  color: white;
}

.selected-range-date {
  background-color: rgb(64, 240, 64);
  color: black;
}

.date_visible {
  color: white;
  margin: 2px;
  font-size: 18px;
}

.cir1 {
  color: #a2fd19;
}

.cir2 {
  color: red;
}

.cir3 {
  color: #ffa500;
}

.date_visible_span {
  margin-right: 50px !important
}


/* all calender css new   */

/* Custom DatePicker Styles */


.custom-datepicker {
  border-radius: 8px;
  padding: 20px;
}


.react-datepicker {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border-radius: 8px;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  /* background-color: #ffc800; */
  color: white;
}

.react-datepicker__day:hover {
  background-color: #e2e6ea;
  /* Light gray on hover */
  color: black;
  font-weight: bold;
}

.react-datepicker__day--highlighted {
  background-color: #ffeeba;
  /* Highlighted days */
  color: #856404;
}

.react-datepicker__header {
  background-color: #f9cd2e;
  padding-top: 30px;
  border-bottom: none;
}

.react-datepicker__day-name {
  margin: 11.5px;
}

.react-datepicker__current-month {
  font-weight: bold;
  font-size: 20px;
  color: black;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  top: 30px;
  font-size: 15px !important;


}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  /* background-color: red; */
  border-color: black;
}

.react-datepicker__month {
  margin: 10px;
  padding: 20px;
}



.custom-datepicker .react-datepicker__day {
  width: 25px;
  border-radius: 50%;
  cursor: pointer;
  margin: 12px;
}


/* Vertical line between calendars */

.custom-datepicker::before {
  content: '';
  position: absolute;
  top: 430px;
  /* margin-top: 10%; */
  bottom: 0;
  left: 50%;
  height: 250px;
  width: 2px;
  background-color: #ccc;
  z-index: 1;
}

.date_pick_btn {
  background-color: #ffc800;
  color: black;
  font-size: 25px;
  margin: auto;
  width: 54%;
  margin-bottom: 0px;
  padding: 5px;
  border: 0;
  text-decoration: none;
  border-radius: 8px;
}


@media only screen and (max-width: 600px) {
  .person_week {    
    font-size: 15px !important;
  }

  .date_pick_btn {
    background-color: #ffc800;
    color: black;
    font-size: 20px;
    margin: auto;
    width: 100%;
    border: 0;
    text-decoration: none;
    border-radius: 8px;
  }

.date_visible{
  max-width: 240px !important;
  margin-left: 4rem;
}

}


/* Adjustments for mobile */
@media (max-width: 768px) {

  .datepicker_cont {
    margin: 0;
    padding: 0;
  }

  .react-datepicker {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .custom-datepicker .react-datepicker__day {
    width: 25px;
    border-radius: 50%;
    cursor: pointer;
    margin: 7px;
  }

  .react-datepicker__day-name {
    margin: 5px;
  }

  .datepicker_cont {
    max-width: 800px !important;
  }

  .custom-datepicker::before {
    display: none;
  }

  .date_visible_span {
    margin: 5px !important
  }

  .react-datepicker__month {
    margin: 0px;
    padding: 0px;
  }

  .react-datepicker__month-container {
    width: 100%;

  }

}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 1024px) and (min-width: 500px) {
  .date_visible_span {
    margin: 2px !important
  }

  .custom-datepicker::before {
    display: none;
  }

  .react-datepicker {
    /* max-width: 100% !important; */
    width: 55.5%;
    margin: 0;
    padding: 0;
  }
}
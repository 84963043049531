.video-container {
  position: relative;
  width: 100%;
  height: 117vh;
  overflow: hidden;
  margin-top: -10%;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
  /* margin-top: 10rem; */
}

.content {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  margin-top: 20%;
}

.hero_heading1 {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  font-family: sans-serif;
  text-transform: uppercase;
}

.hero_heading2 {
  color: white;
  font-weight: 900;
  font-size: 55px !important;
  text-transform: uppercase;

}

.logo_for_phone_mode {
  display: none;
}


/* select boxes search box  */
.search_filter_herosection{
  margin-top: 4rem;
}

.select_box_div {
  max-width: 800px;
  padding: 0 5px 0 0;
  justify-content: center !important;
  text-align: center !important;
  margin: auto !important;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #fdc016 !important;
}

.inputbox_row .form-select {
  border-radius: 5px;
  border: none !important;
  padding: 0.5rem;
  width: 70% !important;
  color: black;
  font-weight: 500;
  background-color: transparent !important;
}


.searchbtn {
  display: inline-block;
  width: fit-content;
  min-width: 120px;
  height: 45px;
  padding: 10px 16px;
  border-radius: 5px;
  cursor: pointer;
  background-color: black;
  color: white;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  user-select: none;
  font-family: 'Poppins', sans-serif;
}

.searchbtn:hover {
  background-color: #fdc016;
  color: black;
  font-size: 16px;
  border: 0;

}

.searchbtn:active {
  transform: scale(0.95);

}

@media only screen and (max-width: 600px) {
  .video-container {
    position: relative;
    width: 100%;
    height: 60vh;
    overflow: hidden;
    /* margin-top: -10% !important; */
  }


  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0;
    transform: translate(-50%, -50%);
    z-index: -1;
    overflow: hidden;
    /* margin-top: -20%; */
  }

  .logo_for_phone_mode {
    display: block;
    margin-top: -8%;
    padding: 0;
  }

  .content {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
    margin-top: 30%;
  }

  .hero_heading1 {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    font-family: sans-serif;
    text-transform: uppercase;
  }

  .hero_heading2 {
    color: white;
    font-weight: 900;
    font-size: 30px !important;
    text-transform: uppercase;

  }

  .search_filter_herosection{
    margin-top: 0;
  }

  .inputbox_row .form-select{
    font-size: 11px !important;
  }

  
.searchbtn {
  display: inline-block;
  width: fit-content;
  min-width: 70px;
  height: 35px;
  padding: 0 0 0 0 ;
  border-radius: 5px;
  cursor: pointer;
  background-color: black;
  color: white;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  user-select: none;
  font-family: 'Poppins', sans-serif;
}

}


/* Medium devices (tablets, 768px and up)---------------- */
@media (max-width: 1024px) and (min-width: 768px) {

  .logo_for_phone_mode {
    display: block;
    margin-top: 5%;
    padding: 0;
  }

  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0;
    transform: translate(-50%, -50%);
    z-index: -1;
    overflow: hidden;
  }

  .video-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }


}
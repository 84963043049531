/* --------------------------------------
Trip CSS
-------------------------------------- */
.video-containers {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  background-color: black;
  /* Fallback to prevent white flashes */
}

.video-containers img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-containers iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 175%;
  object-fit: 'cover',
}
.tab_container {
  transition: all 0.3s ease-in-out;
}

/* open close Accordation  */


.Accordion .accor_btn {
  cursor: pointer;
  padding: 10px;
  margin: 3rem 0 0 0;
  width: 100%;
  text-align: left;
  border: 1px solid rgb(0, 0, 0);
  outline: none;
  background-color: transparent;
  transition: background-color 0.2s ease;
  font-family: "Amatic SC", sans-serif;
  font-weight: bolder;
  font-size: 25px;
}

.accor_arrow {
  font-size: 16px;
  float: right;
  font-weight: normal !important;
  margin-right: 20px;
}

.Accordion .accor_btn:hover {
  background-color: #ffffff;
}

.output-container {
  padding: 10px;
  background-color: #fdfaec;
  display: none;
}

.Accordion .output-container {
  display: block;
}


.tab_container.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  /* Ensure it's above other elements */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.trip_tabs {
  font-family: "Fira Sans", sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 18px;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 3% 2.5% 0% 2.5%;
  padding: 0%;
  font-weight: normal;
}

.trip_tabs:hover {
  text-decoration: underline;
}

.trip_tabs:active {
  color: yellow;
}

.tab_scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}


.img_spacing {
  padding: 20%;
}

.img_head {
  font-family: "Amatic SC", sans-serif;
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 30px;
}

.img_para {
  text-align: center;
  color: black;
}

.check_icon {
  width: 2rem;
  height: 2rem;
}

.high_text {
  font-weight: 500;
}




/* Small devices (phones, 576px and up)------------------ */

@media only screen and (max-width: 600px) {
  .output-container {
    padding: 0 !important;
  }

  .surf_para {
    text-align: justify;
  }

  .img_spacing {
    padding: 15%;
  }

  .bottom-popup {
    height: 400px;
    width: 90%;
    left: 5%;
    top: 15%;
    overflow: auto;

  }



  .img_spacing {
    padding: 5%;
  }

  .img_head {
    font-family: "Amatic SC", sans-serif;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 22px;
  }

  .img_para {
    text-align: center;
    color: black;
    font-size: 14px;
  }

  .check_icon {
    width: 2rem;
    height: 2rem;
  }

  .high_text {
    font-weight: 500;
  }


}


@media (max-width: 1024px) and (min-width: 768px) {
  .bottom-popup {
    height: 350px;
    width: 90%;
    left: 5%;
    top: 15%;
    overflow: auto;

  }
}

/* ------------------------------------- */
/* Overview tab css here   */
/* ------------------------------------- */

/* Popup box css for more images section   */

.para_overview {
  color: black;
  font-family: sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  letter-spacing: 2PX;
}


.scroll-1::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroll-1::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #888;
}


.photo_title {
  color: #fdc016;
  font-family: "Amatic SC", sans-serif;
  font-size: 35px;
  font-weight: bolder;
  margin: 1rem 0 0 0;

}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  /* padding: 24px;   */
  scroll-snap-type: y mandatory;
  scroll-padding-top: 24px;
  gap: 30px;
}

.popup-content {
  border-radius: 8px;
  padding: 8px;
  width: 500px;
  height: 600px;
  text-align: center;
}

.popupclose {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 16px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  border: 2px solid #fdc016;

}

.popimg {
  margin-bottom: 15px;
  width: 90%;
  border-radius: 15px;

}


@media only screen and (max-width: 600px) {
  .para_overview {
    color: black;
    font-family: sans-serif;
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    letter-spacing: 2PX;
    text-align: justify;
  }


}


/* --------------------------------
  Package tabs css 
  -------------------------------- */


.pack_cont {
  background-color: transparent !important;
  margin: auto !important;
  justify-content: center !important;
}

.package_btn {
  background-color: #ffffff;
  border: 1px solid rgb(105, 105, 105);
  border-radius: 0.5rem;
  color: #fdc016;
  font-family: "Amatic SC", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.25rem;
  padding: 1rem 1rem;
  margin: 5px;
  width: 25% !important;
  text-align: center;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
}



.package_btn:hover {
  color: black !important;
  transition: 0.8s;
}


@media only screen and (max-width: 550px) {
  .package_btn_div {
    margin: 0 !important;
    padding: 0 !important;
  }

  .package_btn {
    font-size: 20px !important;
    padding: 10px 3px 0 3px;
    width: auto !important;
  }
}




.sum2_heading {
  font-family: "Amatic SC", sans-serif;
  font-size: 40px;
  font-weight: bold;
}

.sum2_container {
  background-color: #fff5da;
  margin-bottom: 5%;
  border-radius: 5px;
}

.sum2 {
  font-family: "Amatic SC", sans-serif;
  font-size: 22px;
  font-weight: bold;
  background-color: transparent;
  color: #0f0f0f;
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  border-radius: 0.25rem;
  text-align: left;
  cursor: pointer;
  position: relative;
}

details>.sum2::after {
  position: absolute;
  content: "+";
  right: 10px;

}

details[open]>.sum2::after {
  position: absolute;
  content: "-";
  right: 10px;

}

details>.sum2::-webkit-details-marker {
  display: none;
}

.sum2__content {
  font-family: Roboto, arial, sans-serif;
  font-size: 10px;
  margin-top: -20px;
  padding: 0px 20px 0px 30px;
  background-color: transparent;
  color: black;
}


.p_faq_heading {
  font-family: "Amatic SC", sans-serif;
  font-size: 40px;
  font-weight: bold;
}

.p_faq_container {
  background-color: transparent;
  margin-bottom: 5%;
  border-radius: 5px;
}

.p_faq {
  font-family: "Amatic SC", sans-serif;
  font-size: 22px;
  font-weight: bold;
  background-color: transparent;
  color: #0f0f0f;
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  border-radius: 0.25rem;
  text-align: left;
  cursor: pointer;
  position: relative;
}

details>.p_faq::after {
  position: absolute;
  content: "+";
  right: 10px;

}

details[open]>.p_faq::after {
  position: absolute;
  content: "-";
  right: 10px;

}

details>.sum2::-webkit-details-marker {
  display: none;
}

.p_faq__content {
  font-family: Roboto, arial, sans-serif;
  font-size: 10px;
  margin-top: -20px;
  padding: 0px 20px 0px 30px;
  background-color: transparent;
  color: black;
}

.logocard_container {
  background-color: #faefce;
  margin-bottom: 5%;
  border-radius: 15px;
}

.logo_img_contaienr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 10%;
}

.logo_img {
  width: 40%;
  max-width: 100%;
}

/* .text-center {
    margin-top: 10px;
  } */

/* ---------------------------
  Accomodation
  --------------------------- */



.box-accom-custom {
  padding-bottom: 30px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.2);
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 20px;
  height: 100%;
  /* width: 31.33% !important; */
}

.over-img-accomodation {
  border-radius: 20px 20px 0px 0px;
  width: 100%;
}

.box-accom-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-top: 10px;
  font-family: "Amatic SC";
}

.box-accom-des {
  text-align: center;
  font-size: 16px;
  color: #000;
  padding: 0px 10px;
}


/* --------------------------
  Included
  -------------------------- */

.included_desc {
  font-size: 20px;
  font-weight: lighter;
  text-align: center;
  padding: 0% 5% 0% 5% !important;
}

.level_heading_card {
  background-color: #fdc016;
  text-align: center;
  align-items: center;
  padding: 5%;
  border-radius: 5px;
  margin-bottom: 2%;
}

.includ_heading {
  font-size: 20px;
  color: #000;
  font-weight: bold;
}

.week_btn {
  width: 100%;
  align-items: center;
  height: 40px;
  border: solid;
  border-color: rgb(197, 197, 197);
  background-color: rgb(223, 223, 223);
}

.included_card {
  border: 2px solid;
  height: 100%;
}

/* -----------------------------
  Review Card 
  ----------------------------- */
.review_crd {
  height: 45vh !important;
}

.review_carousel-container {
  margin: auto;
  padding: 0 15px;
}

.review_carousel-item-padding-40-px {
  padding: 10px;
}

.review_review-card {
  background-color: #f3eedc;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review_image-container {
  display: flex;
  align-items: center;
}

.review_rounded-circle {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 50%;
}

.review_card-body {
  display: flex;
  flex-direction: column;
}

.review_name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.review_date {
  font-size: 14px;
  margin-bottom: 10px;
}

.review_para {
  font-size: 14px;
}

/* -----------------------
  Hosted css
  ----------------------- */

.hosted_card {
  border-radius: 15px;
}

.hosted_card_title {
  margin-top: 20px;
  font-family: "Amatic SC", sans-serif;
  font-size: 30px;
  font-weight: 500;
  text-transform: capitalize;
  color: #fdc016;
}

.hosted_card_year {
  color: #fdc016;
}

.hosted_card_last {
  font-size: 13px;
}

.hosted_card_last2 {
  margin-top: -10px;
  font-size: 13px;
}